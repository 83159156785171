const TestIDConstants = {
    TITLE: 'TITLE',
    PLEASE_PROVIDE_TITLE: 'PLEASE_PROVIDE_TITLE',
    LOCATION: 'LOCATION',
    NOTE: 'NOTE',
    FORM_ONE: 'FORM_ONE',
    FORM_TWO: 'FORM_TWO',
    FORM_THREE: 'FORM_THREE',
    JOIN_NOW_FORM: 'JOIN_NOW_FORM',
    EMAIL: 'EMAIL',
    FIRST_NAME: 'FIRST_NAME',
    PLEASE_PROVIDE_VALID_EMAIL: 'PLEASE_PROVIDE_VALID_EMAIL',
    PLEASE_PROVIDE_EMAIL: 'PLEASE_PROVIDE_EMAIL',
    PLEASE_PROVIDE_FIRST_NAME: 'PLEASE_PROVIDE_FIRST_NAME',
    DATE: 'DATE',
    PLEASE_PROVIDE_DATE: 'PLEASE_PROVIDE_DATE',
    TIME: 'TIME',
    PLEASE_PROVIDE_TIME: 'PLEASE_PROVIDE_TIME'
}

export default TestIDConstants